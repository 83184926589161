export type Country = {
    name: string;
    value: number;
}

export type State = {
    code: string;
    name: string;
};

export type FieldLabels = {
    city?: string;
    cityAndState?: string;
    state?: string;
    useAreaCode?: boolean;
    zip?: string
}


export type CountryInfo = {
    equate: number,
    iso: string,
    name: string,
    states: Array<State>,
    labels: FieldLabels,
}

export type StreetAddress = {
    address?: string;
    address2?: string;
    address3?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
}

export type ContactInfo = {
    phone?: string;
    fax?: string;
}

export enum CurrencyEquate {
    Dollar = 1,
    Pound = 2,
    Euro = 3,
    Peso = 4
}