import ReCAPTCHA from "react-google-recaptcha";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../app/hooks';
import * as Yup from 'yup';
import { useAppDispatch } from '../../app/hooks';
import { CountryCodes } from '../../common/CountryCodes';
import PopoverLink from '../../common/PopoverLink';
import { uniformPayActions } from './uniformPaySlice';
import { FullSteamPaymentsParameters, UniformPayFormFields, UniformPayProps } from './uniformPayTypes';
import ModalPopup from "../modalPopup/ModalPopup";


const UniformPayHostedControls = (props: UniformPayProps) => {
    console.log('UniformPayHostedControls intializing');

    const state = useAppSelector((state) => state.uniformPay)

    const inputNameRef = useRef(null);
    const dispatch = useAppDispatch();

    const formFields: UniformPayFormFields = {
        country: props.country || '',
        nameOnAccount: props.nameOnAccount || '',
        zip: props.zip || ''
    }

    const formId = props.operationType + '-form';


    useEffect(() => {
        console.log('UniformPayHostedControls useEffect');

        dispatch(uniformPayActions.enablePayButton());
        // We don't want to display the form until the input fields are created
        // and the reCaptcha control (if needed) is shown. Set up an observer to 
        // look for these fields and set the state accordingly
        let observer = new MutationObserver(function (mutations) {
            if (document.getElementById('fullsteam-hosted-expiration-year-frame')) {
                //// console.log("form is loaded");
                // @ts-ignore
                DDS_setUniformPayFormLoaded();
                observer.disconnect();
                // @ts-ignore
                inputNameRef.current.focus();
                console.log('form loaded, set focus')
            }
        });

        observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
        activateForm(props.authenticationKey);
        // }

        // if (!upState.blacklisted && !upState.lockedOut && !props.zipMismatch) {
        //     // @ts-ignore
        //     inputNameRef.current.focus();
        // }
    }, []);


    let cardNumberPromptClass = ['uniformpay-prompt'];
    let expiryMonthPromptClass = ['uniformpay-prompt'];
    let expiryYearPromptClass = ['uniformpay-prompt'];
    let cvvPromptClass = ['uniformpay-prompt'];
    let cardNumberIFrameClass = ['uniformpay-iframe'];
    let expiryMonthIFrameClass = ['uniformpay-iframe', 'field-small'];
    let expiryYearIFrameClass = ['uniformpay-iframe', 'field-small'];
    let cvvIFrameClass = ['uniformpay-iframe', 'field-small'];
    let cardNumberErrorClass = ['hide'];
    let expiryMonthErrorClass = ['hide'];
    let expiryYearErrorClass = ['hide'];
    let cvvErrorClass = ['hide'];
    let method = 'get';
    //console.log('method ' + method);

    if (!state.cardNumberValid) {
        cardNumberPromptClass.push('is-invalid-label');
        cardNumberIFrameClass.push('is-invalid-input');
        cardNumberErrorClass = ['uniformpay-error', 'is-visible'];
    } else if (state.cardNumberHasFocus) {
        cardNumberIFrameClass.push('uniformPay-hasFocus');
    }
    if (!state.expiryMonthValid) {
        expiryMonthPromptClass.push('is-invalid-label');
        expiryMonthIFrameClass.push('is-invalid-input');
        expiryMonthErrorClass = ['uniformpay-error', 'is-visible'];
    } else if (state.expiryMonthHasFocus) {
        expiryMonthIFrameClass.push('uniformPay-hasFocus');
    }
    if (!state.expiryYearValid) {
        expiryYearPromptClass.push('is-invalid-label');
        expiryYearIFrameClass.push('is-invalid-input');
        expiryYearErrorClass = ['uniformpay-error', 'is-visible'];
    } else if (state.expiryYearHasFocus) {
        expiryYearIFrameClass.push('uniformPay-hasFocus');
    }
    if (!state.cvvValid) {
        cvvPromptClass.push('is-invalid-label');
        cvvIFrameClass.push('is-invalid-input');
        cvvErrorClass = ['uniformpay-error', 'is-visible'];
    } else if (state.cvvHasFocus) {
        cvvIFrameClass.push('uniformPay-hasFocus');
    }

    const yupSchema = Yup.object().shape({
        zip: Yup.string()
            .required('Required'),
        nameOnAccount: Yup.string()
            .required('Required'),
    });
    // const [disablePayButton, setDisablePayButton] = useState(((pointOfSale || disableRecaptcha) ? false : true));
    // const [forceDisablePayButton, setForceDisablePayButton] = useState(false);

    const payPhrase = props.operationType === 'Payment' ? 'Pay ' + props.displayAmount : 'Get Token';

    // let visibility: string = 'hidden';
    // if (upState.formLoaded && !upState.lockedOut && !upState.blacklisted && !props.zipMismatch) visibility = 'visible';


    const activateForm = (authKey: string) => {
        console.log('activateForm');
        let activateData: FullSteamPaymentsParameters = {
            // achAuthorizationType: 1,
            authenticationKey: authKey,
            cardEntryContext: props.cardEntryContext,
            customerId: props.customerId || '',
            disableDuplicateDetection: props.disableDuplicateDetection,
            formId: formId,
            invoiceNumber: props.invoiceNumber || '',
            nameOnAccountField: 'nameOnAccount',
            operationType: props.operationType,
            paymentAmount: props.paymentAmount || 0,
            requestId: props.requestId || '',
            // paymentSourceType: 'Card',
        };
        // @ts-ignore
        activateData.gatewayErrorCallback = handleGatewayError;

        if (typeof props.zipEdit === 'undefined' || props.zipEdit) {
            // default to editing zip/postal
            activateData.zipField = 'zip'
            activateData.countryField = 'country'
        } else {
            activateData.zip = props.zip
            activateData.country = props.country;
        }

        activateData.firstName = props.firstName;
        activateData.lastName = props.lastName;
        activateData.address1 = props.address1;
        activateData.address2 = props.address2;
        activateData.address3 = props.address3;
        activateData.city = props.city;
        activateData.state = props.state;
        activateData.phone = props.phone;
        //formData.zip: props.zip 
        // if (props.testing) {
        //     activateData.disableDuplicateDetection = true;
        // }
        if (props.operationType === 'Payment') {
            activateData.paymentAmount = props.paymentAmount;
        }
        console.log('before setupFullsteamHostedPayments', activateData);
        // @ts-ignore
        setupFullsteamHostedPayments(activateData);
    }


    return (
        <>
            {!state.formLoaded ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : null}
            {props.zipMismatch ? (
                <div id="alert_div" className="callout nt-alert ui-state-error">
                    Your payment cannot be processed because the credit card payment zip code is not the same as the shipping address zip code.
                </div>
            ) : (
                <>
                    {state.lockedOut || state.blacklisted ? (
                        <>
                            {state.blacklisted ? (
                                <div id="alert_div" className="callout nt-alert ui-state-error">
                                    <strong>Credit cards have failed authorization too many times. You are no longer allowed to attempt a credit card payment (Error #101.2).</strong>
                                </div>
                            ) : (
                                <div id="alert_div" className="callout nt-alert ui-state-error">
                                    <strong>Your payment attempt has failed to be authorized after five attempts. You can try again in four hours (Error #101.1)</strong>
                                </div>
                            )}
                        </>
                    ) : (
                        // <Box component="div" sx={{ display: upState.formLoaded ? 'block' : 'none' }}>
                        <Box component="div" sx={{ display: state.formLoaded ? 'block' : 'block' }}>
                            {state.errors && state.errors.length > 0 ? (
                                <>
                                    <h1>Unable to initialize form</h1>
                                    {state.errors.map((error: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
                                        <h2 key={index}>{error}</h2>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{ zip: formFields.zip, country: formFields.country, nameOnAccount: formFields.nameOnAccount }}
                                        validationSchema={yupSchema}
                                        onSubmit={(values, { setSubmitting }) => {
                                            setTimeout(() => {
                                                alert(JSON.stringify(values, null, 2));
                                                setSubmitting(false);
                                            }, 400);
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            isValid,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            /* and other goodies */
                                        }) => (
                                            <form id={formId} action="/CheckoutProcessOrder" method={method}>
                                                <Grid container padding={0} spacing={0} border={0}>
                                                    <Grid item xs={12}>
                                                        <Box
                                                            sx={(theme) => ({
                                                                padding: theme.spacing(0),
                                                            })}
                                                        >
                                                            <label className={`${errors.nameOnAccount && touched.nameOnAccount ? 'is-invalid-label' : null}`}>
                                                                Name on Card
                                                            </label>
                                                            <input
                                                                autoFocus
                                                                ref={inputNameRef}
                                                                className={`${errors.nameOnAccount && touched.nameOnAccount ? 'is-invalid-input form-control' : 'form-control'}`}
                                                                type="text"
                                                                id="nameOnAccount"
                                                                name="nameOnAccount"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                defaultValue={values.nameOnAccount}
                                                            />
                                                            {errors.nameOnAccount && touched.nameOnAccount && <span className="form-error is-visible">Name on Card is required</span>}

                                                            {/* <div className="text-danger field-validation-valid" data-valmsg-for="nameOnAccount" data-valmsg-replace="true"></div> */}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box
                                                            sx={(theme) => ({
                                                                padding: theme.spacing(0),
                                                            })}
                                                        >
                                                            <div className={cardNumberPromptClass.join(' ')}>Card Number</div>
                                                            <div className={cardNumberIFrameClass.join(' ')} id="fullsteam-hosted-card-number-div"></div>
                                                            <div className={cardNumberErrorClass.join(' ')} id="fullsteam-hosted-card-number-error">
                                                                Credit card number is invalid
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={(theme) => ({
                                                                padding: theme.spacing(0),
                                                                marginRight: '.5rem',
                                                            })}
                                                        >
                                                            <div className={expiryMonthPromptClass.join(' ')}>Exp. Month</div>
                                                            <div className={expiryMonthIFrameClass.join(' ')} id="fullsteam-hosted-expiration-month-div"></div>
                                                            <div className={expiryMonthErrorClass.join(' ')} id="fullsteam-hosted-expiration-month-error">
                                                                Select an expiry month
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={(theme) => ({
                                                                padding: theme.spacing(0),
                                                                marginLeft: '.5rem',
                                                            })}
                                                        >
                                                            <div className={expiryYearPromptClass.join(' ')}>Exp. Year</div>
                                                            <div className={expiryYearIFrameClass.join(' ')} id="fullsteam-hosted-expiration-year-div"></div>
                                                            <div className={expiryYearErrorClass.join(' ')} id="fullsteam-hosted-expiration-year-error">
                                                                Select an expiry year
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={(theme) => ({
                                                                padding: theme.spacing(0),
                                                                marginRight: '.5rem',
                                                            })}
                                                        >
                                                            <div className={cvvPromptClass.join(' ')}>
                                                                CVV&nbsp;
                                                                <PopoverLink
                                                                    linkText="(what's this?)"
                                                                    sx={(theme: { palette: { info: { main: any; }; }; }) => ({
                                                                        color: theme.palette.info.main,
                                                                        hover: {
                                                                            cursor: 'default',
                                                                        },
                                                                    })}
                                                                    text="For Visa and Mastercard, the CVV number is a three digit number on the back of the card. For American Express the CVV is a four digit number on the front of the card, just above the credit card number."
                                                                />
                                                            </div>
                                                            <div className={cvvIFrameClass.join(' ')} id="fullsteam-hosted-cvv-div"></div>
                                                            <div className={cvvErrorClass.join(' ')} id="fullsteam-hosted-cvv-error">
                                                                CVV number is invalid
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={(theme) => ({
                                                                padding: theme.spacing(0),
                                                                marginLeft: '.5rem',
                                                            })}
                                                        >
                                                            <label className={`${errors.zip && touched.zip ? 'is-invalid-label' : null}`}>
                                                                Zip/Postal Code
                                                            </label>
                                                            <input
                                                                className={`${errors.zip && touched.zip ? 'is-invalid-input uniformpay-form-field-zip' : 'uniformpay-form-field-zip'}`}
                                                                type="text"
                                                                id="zip"
                                                                name="zip"
                                                                readOnly={typeof props.zipEdit !== 'undefined' && !props.zipEdit}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                defaultValue={values.zip.toUpperCase()}
                                                            />
                                                            {errors.zip && touched.zip && <span className="form-error is-visible">Zip/Postal Code is required</span>}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box
                                                            sx={(theme) => ({
                                                                marginBottom: '.8rem',
                                                                padding: theme.spacing(0),
                                                                display: typeof props.zipEdit !== 'undefined' && !props.zipEdit ? 'none' : 'block',
                                                            })}
                                                        >
                                                            <div className="form-prompt">Country</div>
                                                            <select
                                                                className="uniformpay-form-field-zip"
                                                                id="country"
                                                                name="country"
                                                                disabled={typeof props.countryEdit !== 'undefined' && !props.countryEdit}
                                                                onChange={handleChange}
                                                                value={values.country}
                                                            >
                                                                {CountryCodes.map((country, index) =>
                                                                    <option key={country.key} value={country.code}>{country.name}</option>
                                                                )};
                                                            </select>
                                                            {/* <input className="uniformpay-form-field-zip" type="text" id="country" name="country" readOnly={true} defaultValue={inputCountry} /> */}
                                                            <div className="text-danger field-validation-valid" data-valmsg-for="Country" data-valmsg-replace="true"></div>
                                                        </Box>
                                                    </Grid>
                                                    {state.recaptchaEnabled ? (
                                                        <Grid item xs={12} >
                                                            <Box
                                                                id="recaptchaWrapper"
                                                                sx={{
                                                                    marginBottom: '.8rem',
                                                                }}
                                                            >
                                                                <ReCAPTCHA
                                                                    sitekey="6LcbdaMZAAAAAHckuPOLyKJ7YG3oYtnUZwl6JJjD"
                                                                    onChange={(data: any) => {
                                                                        console.log(data);
                                                                        dispatch(uniformPayActions.enablePayButton());
                                                                    }}
                                                                    onExpired={() => {
                                                                        dispatch(uniformPayActions.disablePayButton());
                                                                    }}
                                                                    onErrored={() => {
                                                                        dispatch(uniformPayActions.disablePayButton());
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    ) : null}
                                                    <Grid item xs={12}>
                                                        {/* <input type="submit" id="form-submit" value={payPhrase} className="button uniformpay-full-width" disabled={upState.payButtonDisabled} /> */}
                                                        <input type="submit" id="form-submit" value={payPhrase} className="button uniformpay-full-width" disabled={!isValid || state.payButtonDisabled} />
                                                    </Grid>
                                                </Grid>
                                                <ModalPopup resetCaptchaOnClose={state.recaptchaEnabled} key={Date.now()} />

                                                <input type="hidden" name="tp" id="PV__WebCCProcessorParameter" value="up" />
                                                <input type="hidden" name="TransactionId" id="TransactionId" />
                                                <input type="hidden" name="AccountToken" id="AccountToken" />
                                                <input type="hidden" name="HostedPaymentsResponse" id="HostedPaymentsResponse" />

                                                {/* <pre>
                                                    IsValid: {isValid ? <>valid</> : <>not valid</>}
                                                    <br />
                                                    Errors
                                                    <br />
                                                    {JSON.stringify(errors, null, 2)}
                                                </pre>
                                                <pre>
                                                    Touched
                                                    <br />
                                                    {JSON.stringify(touched, null, 2)}
                                                </pre>
                                                <pre>
                                                    Values
                                                    <br />
                                                    {JSON.stringify(values, null, 2)}
                                                </pre> */}
                                            </form>

                                        )}
                                    </Formik>
                                </>
                            )}
                        </Box>
                    )}
                </>
            )}
        </>
    );
};


UniformPayHostedControls.defaultProps = {
    sandbox: false,
    testing: false,
    address1: '',
    address2: '',
    address3: '',
    city: '',
    cardEntryContext: 'WebConsumerInitiated',
    customerId: '',
    disableDuplicateDetection: false,
    firstName: '',
    invoiceNumber: '',
    lastName: '',
    operationType: 'Payment',
    phone: '',
    requestId: '',
    state: ''
}

export default UniformPayHostedControls;
