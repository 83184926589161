import { ContactInfo, Country, StreetAddress } from "../../common/Types";
import { AccountSalesBrowseData, BrowseSettings } from "../account/AccountTypes";

export type StoreInfo = StreetAddress & ContactInfo & {
    number: number;
    name: string;
}

export enum LoadState {
    Unknown = 0,
    Loading,
    Loaded
}

export type UserState = {
    // All required properties must have corresponding class properties in Usw_Web_UserSettings.inc that are mapped in Usw_Web_UserSettings.clw
    activeContractName?: string;
    billingAddressState?: number;
    countries?: Array<Country>;
    currencyEquate?: number;
    currencySymbol?: string;
    error?: string;
    isMixedModeTestingEnabled?: boolean; // If true then some requests for state data will go to the USW web server on port 80
    isSelectStoreEnabled?: boolean; // If true user can click to change store 
    isSelectStoreVisible?: boolean; // If false they can click to change will get a message explaining why they cannot choose
    isSignedIn?: boolean;
    isStoreSelectionRequired?: boolean; // If true the user cannot proceed without selecting a store
    loadState?: number;
    mixedModeServerPort?: number;
    salesBrowseData?: AccountSalesBrowseData;
    salesBrowseSettings: BrowseSettings;
    selectedStoreName?: string;
    selectedStoreNumber?: number;
    showStoreList?: boolean;
    stores?: Array<StoreInfo>;
    storeSelectionMode?: number;
    // accountData: AccountData;
}

export enum StoreSelectionMode {
    Enabled = 0,
    Hidden,
    Disabled
}

