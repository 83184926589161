import { TooltipClassKey } from "@mui/material";

export type BrowseSettings = {
    id?: BrowseId;
    loading?: boolean;
    balanceColumnSorted?: boolean;
    browseAction?: BrowseAction;
    browseAtBottom?: boolean;
    browseAtTop?: boolean;
    browseId?: string;
    browseType?: string;
    columnSortDirection?: string;
    currentPage?: number;
    dateColumnSorted?: boolean;
    endDate?: string;
    goTo?: string;
    invoiceColumnSorted?: boolean;
    isFormSubmission?: boolean;
    isBrowseReadyForUse?: boolean;
    isMixedModeTestingEnabled?: boolean;
    itemsPerPage?: number;
    load?: boolean;
    locator?: string; // Only used to pass a value back to the server, for backward compatibility
    filterColumn?: BrowseColumnName;
    locatorPositionTo?: boolean;
    filterValue?: string;
    perPage100?: boolean;
    perPage10?: boolean;
    perPage25?: boolean;
    perPage50?: boolean;
    refreshCounter?: number;
    showSubAccount?: boolean;
    soldTo?: string;
    soldToColumnSorted?: boolean;
    sortColumn?: string;
    sortColumnPrevious?: string;
    sortDirection?: string;
    startDate?: string;
    totalColumnSorted?: boolean;
    value?: string; // Only used to pass a value back to the server, for backward compatibility
}

// export enum BrowseReady {
//     NotReady = 0,   // Don't load the browse
//     ReadyForLoad,   // Load from the 
//     ReadyForUse,
// }


export type BrowseColumnSort = {
    // id: BrowseId;
    columnName: BrowseColumnName;
}

export type BrowseLoad = {
    id: BrowseId;
    load: boolean;
}

// export type BrowseGoToPage = {
//     // id: BrowseId;
//     goTo: string;
// }

export type BrowseFilter = {
    // id: BrowseId;
    columnName: BrowseColumnName;
    value: string;
    positionTo: boolean;
}

export type AccountSalesBrowseData = {
    invoiceColHeader: string;
    rows: Array<AccountSalesRow>;
}

export type AccountSalesRow = {
    balance: string;
    date: string;
    invoice: string;
    name: string;
    open: string;
    ordered: string;
    printId: string;
    shipped: string;
    total: string
}

export type AccountSalesBrowseResponse = BrowseSettings & AccountSalesBrowseData;

export enum BrowseId {
    Sales = 0

}

export enum BrowseAction {
    None = 0,
    Sort = 1,
    GoTo = 2,
    Filter = 3,
    ItemsPerPage = 4,
}

export enum BrowseColumnName {
    none = '',
    date = 'date',
    invoice= 'invoice',
    soldTo = 'soldTo',
    total = 'total',
    balance = 'balance',
}

export enum BrowseGoTo {
    FIRST = 'first',
    LAST = 'last',
    NEXT = 'next',
    PREVIOUS = 'prev',
}
