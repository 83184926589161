export const CountryCodes =
    [
        {
            key: "US",
            code: "US",
            name: "United States"
        },
        {
            key: "CA",
            code: "CA",
            name: "Canada"
        },
        {
            key: "MX",
            code: "MX",
            name: "Mexico"
        },
        {
            key: "AF",
            code: "AF",
            name: "Afghanistan"
        },
        {
            key: "AX",
            code: "AX",
            name: "\u00c5land Islands"
        },
        {
            key: "AL",
            code: "AL",
            name: "Albania"
        },
        {
            key: "DZ",
            code: "DZ",
            name: "Algeria"
        },
        {
            key: "AS",
            code: "AS",
            name: "American Samoa"
        },
        {
            key: "AD",
            code: "AD",
            name: "Andorra"
        },
        {
            key: "AO",
            code: "AO",
            name: "Angola"
        },
        {
            key: "AI",
            code: "AI",
            name: "Anguilla"
        },
        {
            key: "AQ",
            code: "AQ",
            name: "Antarctica"
        },
        {
            key: "AG",
            code: "AG",
            name: "Antigua and Barbuda"
        },
        {
            key: "AR",
            code: "AR",
            name: "Argentina"
        },
        {
            key: "AM",
            code: "AM",
            name: "Armenia"
        },
        {
            key: "AW",
            code: "AW",
            name: "Aruba"
        },
        {
            key: "AU",
            code: "AU",
            name: "Australia"
        },
        {
            key: "AT",
            code: "AT",
            name: "Austria"
        },
        {
            key: "AZ",
            code: "AZ",
            name: "Azerbaijan"
        },
        {
            key: "BS",
            code: "BS",
            name: "Bahamas"
        },
        {
            key: "BH",
            code: "BH",
            name: "Bahrain"
        },
        {
            key: "BD",
            code: "BD",
            name: "Bangladesh"
        },
        {
            key: "BB",
            code: "BB",
            name: "Barbados"
        },
        {
            key: "BY",
            code: "BY",
            name: "Belarus"
        },
        {
            key: "BE",
            code: "BE",
            name: "Belgium"
        },
        {
            key: "BZ",
            code: "BZ",
            name: "Belize"
        },
        {
            key: "BJ",
            code: "BJ",
            name: "Benin"
        },
        {
            key: "BM",
            code: "BM",
            name: "Bermuda"
        },
        {
            key: "BT",
            code: "BT",
            name: "Bhutan"
        },
        {
            key: "BO",
            code: "BO",
            name: "Bolivia, Plurinational State of"
        },
        {
            key: "BQ",
            code: "BQ",
            name: "Bonaire, Sint Eustatius and Saba"
        },
        {
            key: "BA",
            code: "BA",
            name: "Bosnia and Herzegovina"
        },
        {
            key: "BW",
            code: "BW",
            name: "Botswana"
        },
        {
            key: "BV",
            code: "BV",
            name: "Bouvet Island"
        },
        {
            key: "BR",
            code: "BR",
            name: "Brazil"
        },
        {
            key: "IO",
            code: "IO",
            name: "British Indian Ocean Territory"
        },
        {
            key: "BN",
            code: "BN",
            name: "Brunei Darussalam"
        },
        {
            key: "BG",
            code: "BG",
            name: "Bulgaria"
        },
        {
            key: "BF",
            code: "BF",
            name: "Burkina Faso"
        },
        {
            key: "BI",
            code: "BI",
            name: "Burundi"
        },
        {
            key: "KH",
            code: "KH",
            name: "Cambodia"
        },
        {
            key: "CM",
            code: "CM",
            name: "Cameroon"
        },
        {
            key: "CA2",
            code: "CA",
            name: "Canada"
        },
        {
            key: "CV",
            code: "CV",
            name: "Cape Verde"
        },
        {
            key: "KY",
            code: "KY",
            name: "Cayman Islands"
        },
        {
            key: "CF",
            code: "CF",
            name: "Central African Republic"
        },
        {
            key: "TD",
            code: "TD",
            name: "Chad"
        },
        {
            key: "CL",
            code: "CL",
            name: "Chile"
        },
        {
            key: "CN",
            code: "CN",
            name: "China"
        },
        {
            key: "CX",
            code: "CX",
            name: "Christmas Island"
        },
        {
            key: "CC",
            code: "CC",
            name: "Cocos (Keeling) Islands"
        },
        {
            key: "CO",
            code: "CO",
            name: "Colombia"
        },
        {
            key: "KM",
            code: "KM",
            name: "Comoros"
        },
        {
            key: "CG",
            code: "CG",
            name: "Congo"
        },
        {
            key: "CD",
            code: "CD",
            name: "Congo, the Democratic Republic of the"
        },
        {
            key: "CK",
            code: "CK",
            name: "Cook Islands"
        },
        {
            key: "CR",
            code: "CR",
            name: "Costa Rica"
        },
        {
            key: "CI",
            code: "CI",
            name: "C\u00f4te d'Ivoire"
        },
        {
            key: "HR",
            code: "HR",
            name: "Croatia"
        },
        {
            key: "CU",
            code: "CU",
            name: "Cuba"
        },
        {
            key: "CW",
            code: "CW",
            name: "Cura\u00e7ao"
        },
        {
            key: "CY",
            code: "CY",
            name: "Cyprus"
        },
        {
            key: "CZ",
            code: "CZ",
            name: "Czech Republic"
        },
        {
            key: "DK",
            code: "DK",
            name: "Denmark"
        },
        {
            key: "DJ",
            code: "DJ",
            name: "Djibouti"
        },
        {
            key: "DM",
            code: "DM",
            name: "Dominica"
        },
        {
            key: "DO",
            code: "DO",
            name: "Dominican Republic"
        },
        {
            key: "EC",
            code: "EC",
            name: "Ecuador"
        },
        {
            key: "EG",
            code: "EG",
            name: "Egypt"
        },
        {
            key: "SV",
            code: "SV",
            name: "El Salvador"
        },
        {
            key: "GQ",
            code: "GQ",
            name: "Equatorial Guinea"
        },
        {
            key: "ER",
            code: "ER",
            name: "Eritrea"
        },
        {
            key: "EE",
            code: "EE",
            name: "Estonia"
        },
        {
            key: "ET",
            code: "ET",
            name: "Ethiopia"
        },
        {
            key: "FK",
            code: "FK",
            name: "Falkland Islands (Malvinas)"
        },
        {
            key: "FO",
            code: "FO",
            name: "Faroe Islands"
        },
        {
            key: "FJ",
            code: "FJ",
            name: "Fiji"
        },
        {
            key: "FI",
            code: "FI",
            name: "Finland"
        },
        {
            key: "FR",
            code: "FR",
            name: "France"
        },
        {
            key: "GF",
            code: "GF",
            name: "French Guiana"
        },
        {
            key: "PF",
            code: "PF",
            name: "French Polynesia"
        },
        {
            key: "TF",
            code: "TF",
            name: "French Southern Territories"
        },
        {
            key: "GA",
            code: "GA",
            name: "Gabon"
        },
        {
            key: "GM",
            code: "GM",
            name: "Gambia"
        },
        {
            key: "GE",
            code: "GE",
            name: "Georgia"
        },
        {
            key: "DE",
            code: "DE",
            name: "Germany"
        },
        {
            key: "GH",
            code: "GH",
            name: "Ghana"
        },
        {
            key: "GI",
            code: "GI",
            name: "Gibraltar"
        },
        {
            key: "GR",
            code: "GR",
            name: "Greece"
        },
        {
            key: "GL",
            code: "GL",
            name: "Greenland"
        },
        {
            key: "GD",
            code: "GD",
            name: "Grenada"
        },
        {
            key: "GP",
            code: "GP",
            name: "Guadeloupe"
        },
        {
            key: "GU",
            code: "GU",
            name: "Guam"
        },
        {
            key: "GT",
            code: "GT",
            name: "Guatemala"
        },
        {
            key: "GG",
            code: "GG",
            name: "Guernsey"
        },
        {
            key: "GN",
            code: "GN",
            name: "Guinea"
        },
        {
            key: "GW",
            code: "GW",
            name: "Guinea-Bissau"
        },
        {
            key: "GY",
            code: "GY",
            name: "Guyana"
        },
        {
            key: "HT",
            code: "HT",
            name: "Haiti"
        },
        {
            key: "HM",
            code: "HM",
            name: "Heard Island and McDonald Islands"
        },
        {
            key: "VA",
            code: "VA",
            name: "Holy See (Vatican City State)"
        },
        {
            key: "HN",
            code: "HN",
            name: "Honduras"
        },
        {
            key: "HK",
            code: "HK",
            name: "Hong Kong"
        },
        {
            key: "HU",
            code: "HU",
            name: "Hungary"
        },
        {
            key: "IS",
            code: "IS",
            name: "Iceland"
        },
        {
            key: "IN",
            code: "IN",
            name: "India"
        },
        {
            key: "ID",
            code: "ID",
            name: "Indonesia"
        },
        {
            key: "IR",
            code: "IR",
            name: "Iran, Islamic Republic of"
        },
        {
            key: "IQ",
            code: "IQ",
            name: "Iraq"
        },
        {
            key: "IE",
            code: "IE",
            name: "Ireland"
        },
        {
            key: "IM",
            code: "IM",
            name: "Isle of Man"
        },
        {
            key: "IL",
            code: "IL",
            name: "Israel"
        },
        {
            key: "IT",
            code: "IT",
            name: "Italy"
        },
        {
            key: "JM",
            code: "JM",
            name: "Jamaica"
        },
        {
            key: "JP",
            code: "JP",
            name: "Japan"
        },
        {
            key: "JE",
            code: "JE",
            name: "Jersey"
        },
        {
            key: "JO",
            code: "JO",
            name: "Jordan"
        },
        {
            key: "KZ",
            code: "KZ",
            name: "Kazakhstan"
        },
        {
            key: "KE",
            code: "KE",
            name: "Kenya"
        },
        {
            key: "KI",
            code: "KI",
            name: "Kiribati"
        },
        {
            key: "KP",
            code: "KP",
            name: "Korea, Democratic People's Republic of"
        },
        {
            key: "KR",
            code: "KR",
            name: "Korea, Republic of"
        },
        {
            key: "KW",
            code: "KW",
            name: "Kuwait"
        },
        {
            key: "KG",
            code: "KG",
            name: "Kyrgyzstan"
        },
        {
            key: "LA",
            code: "LA",
            name: "Lao People's Democratic Republic"
        },
        {
            key: "LV",
            code: "LV",
            name: "Latvia"
        },
        {
            key: "LB",
            code: "LB",
            name: "Lebanon"
        },
        {
            key: "LS",
            code: "LS",
            name: "Lesotho"
        },
        {
            key: "LR",
            code: "LR",
            name: "Liberia"
        },
        {
            key: "LY",
            code: "LY",
            name: "Libya"
        },
        {
            key: "LI",
            code: "LI",
            name: "Liechtenstein"
        },
        {
            key: "LT",
            code: "LT",
            name: "Lithuania"
        },
        {
            key: "LU",
            code: "LU",
            name: "Luxembourg"
        },
        {
            key: "MO",
            code: "MO",
            name: "Macao"
        },
        {
            key: "MK",
            code: "MK",
            name: "Macedonia, the Former Yugoslav Republic of"
        },
        {
            key: "MG",
            code: "MG",
            name: "Madagascar"
        },
        {
            key: "MW",
            code: "MW",
            name: "Malawi"
        },
        {
            key: "MY",
            code: "MY",
            name: "Malaysia"
        },
        {
            key: "MV",
            code: "MV",
            name: "Maldives"
        },
        {
            key: "ML",
            code: "ML",
            name: "Mali"
        },
        {
            key: "MT",
            code: "MT",
            name: "Malta"
        },
        {
            key: "MH",
            code: "MH",
            name: "Marshall Islands"
        },
        {
            key: "MQ",
            code: "MQ",
            name: "Martinique"
        },
        {
            key: "MR",
            code: "MR",
            name: "Mauritania"
        },
        {
            key: "MU",
            code: "MU",
            name: "Mauritius"
        },
        {
            key: "YT",
            code: "YT",
            name: "Mayotte"
        },
        {
            key: "MX2",
            code: "MX",
            name: "Mexico"
        },
        {
            key: "FM",
            code: "FM",
            name: "Micronesia, Federated States of"
        },
        {
            key: "MD",
            code: "MD",
            name: "Moldova, Republic of"
        },
        {
            key: "MC",
            code: "MC",
            name: "Monaco"
        },
        {
            key: "MN",
            code: "MN",
            name: "Mongolia"
        },
        {
            key: "ME",
            code: "ME",
            name: "Montenegro"
        },
        {
            key: "MS",
            code: "MS",
            name: "Montserrat"
        },
        {
            key: "MA",
            code: "MA",
            name: "Morocco"
        },
        {
            key: "MZ",
            code: "MZ",
            name: "Mozambique"
        },
        {
            key: "MM",
            code: "MM",
            name: "Myanmar"
        },
        {
            key: "NA",
            code: "NA",
            name: "Namibia"
        },
        {
            key: "NR",
            code: "NR",
            name: "Nauru"
        },
        {
            key: "NP",
            code: "NP",
            name: "Nepal"
        },
        {
            key: "NL",
            code: "NL",
            name: "Netherlands"
        },
        {
            key: "NC",
            code: "NC",
            name: "New Caledonia"
        },
        {
            key: "NZ",
            code: "NZ",
            name: "New Zealand"
        },
        {
            key: "NI",
            code: "NI",
            name: "Nicaragua"
        },
        {
            key: "NE",
            code: "NE",
            name: "Niger"
        },
        {
            key: "NG",
            code: "NG",
            name: "Nigeria"
        },
        {
            key: "NU",
            code: "NU",
            name: "Niue"
        },
        {
            key: "NF",
            code: "NF",
            name: "Norfolk Island"
        },
        {
            key: "MP",
            code: "MP",
            name: "Northern Mariana Islands"
        },
        {
            key: "NO",
            code: "NO",
            name: "Norway"
        },
        {
            key: "OM",
            code: "OM",
            name: "Oman"
        },
        {
            key: "PK",
            code: "PK",
            name: "Pakistan"
        },
        {
            key: "PW",
            code: "PW",
            name: "Palau"
        },
        {
            key: "PS",
            code: "PS",
            name: "Palestine, State of"
        },
        {
            key: "PA",
            code: "PA",
            name: "Panama"
        },
        {
            key: "PG",
            code: "PG",
            name: "Papua New Guinea"
        },
        {
            key: "PY",
            code: "PY",
            name: "Paraguay"
        },
        {
            key: "PE",
            code: "PE",
            name: "Peru"
        },
        {
            key: "PH",
            code: "PH",
            name: "Philippines"
        },
        {
            key: "PN",
            code: "PN",
            name: "Pitcairn"
        },
        {
            key: "PL",
            code: "PL",
            name: "Poland"
        },
        {
            key: "PT",
            code: "PT",
            name: "Portugal"
        },
        {
            key: "PR",
            code: "PR",
            name: "Puerto Rico"
        },
        {
            key: "QA",
            code: "QA",
            name: "Qatar"
        },
        {
            key: "RE",
            code: "RE",
            name: "R\u00e9union"
        },
        {
            key: "RO",
            code: "RO",
            name: "Romania"
        },
        {
            key: "RU",
            code: "RU",
            name: "Russian Federation"
        },
        {
            key: "RW",
            code: "RW",
            name: "Rwanda"
        },
        {
            key: "BL",
            code: "BL",
            name: "Saint Barth\u00e9lemy"
        },
        {
            key: "SH",
            code: "SH",
            name: "Saint Helena, Ascension and Tristan da Cunha"
        },
        {
            key: "KN",
            code: "KN",
            name: "Saint Kitts and Nevis"
        },
        {
            key: "LC",
            code: "LC",
            name: "Saint Lucia"
        },
        {
            key: "MF",
            code: "MF",
            name: "Saint Martin (French part)"
        },
        {
            key: "PM",
            code: "PM",
            name: "Saint Pierre and Miquelon"
        },
        {
            key: "VC",
            code: "VC",
            name: "Saint Vincent and the Grenadines"
        },
        {
            key: "WS",
            code: "WS",
            name: "Samoa"
        },
        {
            key: "SM",
            code: "SM",
            name: "San Marino"
        },
        {
            key: "ST",
            code: "ST",
            name: "Sao Tome and Principe"
        },
        {
            key: "SA",
            code: "SA",
            name: "Saudi Arabia"
        },
        {
            key: "SN",
            code: "SN",
            name: "Senegal"
        },
        {
            key: "RS",
            code: "RS",
            name: "Serbia"
        },
        {
            key: "SC",
            code: "SC",
            name: "Seychelles"
        },
        {
            key: "SL",
            code: "SL",
            name: "Sierra Leone"
        },
        {
            key: "SG",
            code: "SG",
            name: "Singapore"
        },
        {
            key: "SX",
            code: "SX",
            name: "Sint Maarten (Dutch part)"
        },
        {
            key: "SK",
            code: "SK",
            name: "Slovakia"
        },
        {
            key: "SI",
            code: "SI",
            name: "Slovenia"
        },
        {
            key: "SB",
            code: "SB",
            name: "Solomon Islands"
        },
        {
            key: "SO",
            code: "SO",
            name: "Somalia"
        },
        {
            key: "ZA",
            code: "ZA",
            name: "South Africa"
        },
        {
            key: "GS",
            code: "GS",
            name: "South Georgia and the South Sandwich Islands"
        },
        {
            key: "SS",
            code: "SS",
            name: "South Sudan"
        },
        {
            key: "ES",
            code: "ES",
            name: "Spain"
        },
        {
            key: "LK",
            code: "LK",
            name: "Sri Lanka"
        },
        {
            key: "SD",
            code: "SD",
            name: "Sudan"
        },
        {
            key: "SR",
            code: "SR",
            name: "Suriname"
        },
        {
            key: "SJ",
            code: "SJ",
            name: "Svalbard and Jan Mayen"
        },
        {
            key: "SZ",
            code: "SZ",
            name: "Swaziland"
        },
        {
            key: "SE",
            code: "SE",
            name: "Sweden"
        },
        {
            key: "CH",
            code: "CH",
            name: "Switzerland"
        },
        {
            key: "SY",
            code: "SY",
            name: "Syrian Arab Republic"
        },
        {
            key: "TW",
            code: "TW",
            name: "Taiwan, Province of China"
        },
        {
            key: "TJ",
            code: "TJ",
            name: "Tajikistan"
        },
        {
            key: "TZ",
            code: "TZ",
            name: "Tanzania, United Republic of"
        },
        {
            key: "TH",
            code: "TH",
            name: "Thailand"
        },
        {
            key: "TL",
            code: "TL",
            name: "Timor-Leste"
        },
        {
            key: "TG",
            code: "TG",
            name: "Togo"
        },
        {
            key: "TK",
            code: "TK",
            name: "Tokelau"
        },
        {
            key: "TO",
            code: "TO",
            name: "Tonga"
        },
        {
            key: "TT",
            code: "TT",
            name: "Trinidad and Tobago"
        },
        {
            key: "TN",
            code: "TN",
            name: "Tunisia"
        },
        {
            key: "TR",
            code: "TR",
            name: "Turkey"
        },
        {
            key: "TM",
            code: "TM",
            name: "Turkmenistan"
        },
        {
            key: "TC",
            code: "TC",
            name: "Turks and Caicos Islands"
        },
        {
            key: "TV",
            code: "TV",
            name: "Tuvalu"
        },
        {
            key: "UG",
            code: "UG",
            name: "Uganda"
        },
        {
            key: "UA",
            code: "UA",
            name: "Ukraine"
        },
        {
            key: "AE",
            code: "AE",
            name: "United Arab Emirates"
        },
        {
            key: "GB",
            code: "GB",
            name: "United Kingdom"
        },
        {
            key: "US2",
            code: "US",
            name: "United States"
        },
        {
            key: "UM",
            code: "UM",
            name: "United States Minor Outlying Islands"
        },
        {
            key: "UY",
            code: "UY",
            name: "Uruguay"
        },
        {
            key: "UZ",
            code: "UZ",
            name: "Uzbekistan"
        },
        {
            key: "VU",
            code: "VU",
            name: "Vanuatu"
        },
        {
            key: "VE",
            code: "VE",
            name: "Venezuela, Bolivarian Republic of"
        },
        {
            key: "VN",
            code: "VN",
            name: "Viet Nam"
        },
        {
            key: "VG",
            code: "VG",
            name: "Virgin Islands, British"
        },
        {
            key: "VI",
            code: "VI",
            name: "Virgin Islands, U.S."
        },
        {
            key: "WF",
            code: "WF",
            name: "Wallis and Futuna"
        },
        {
            key: "EH",
            code: "EH",
            name: "Western Sahara"
        },
        {
            key: "YE",
            code: "YE",
            name: "Yemen"
        },
        {
            key: "ZM",
            code: "ZM",
            name: "Zambia"
        },
        {
            key: "ZW",
            code: "ZW",
            name: "Zimbabwe"
        }
    ]