// Any changes  to constants here, and which are used in app.js, need to have 
// their corresponding var declarations updated in DDS._header.js

// UniformPay constants
// export const DDS_UP_FIELD_STATUS                = 'DDS_UP_FIELD_STATUS';
// export const DDS_UP_SUBMIT_ERROR                = 'DDS_UP_SUBMIT_ERROR';
// export const DDS_PLEASE_WAIT                    = 'DDS_PLEASE_WAIT';
// export const DDS_ALERT                          = 'DDS_ALERT';
// export const DDS_UP_INPUT_FOCUS                 = 'DDS_UP_INPUT_FOCUS';
export const DDS_UP_FORM_LOADED                 = 'DDS_UP_FORM_LOADED';
// export const DDS_UP_RECAPTCHA_LOADED            = 'DDS_UP_RECAPTCHA_LOADED';
// export const DDS_CART_SUMMARY                   = 'DDS_CART_SUMMARY';
// export const DDS_CART_SUMMARY_UPDATING          = 'DDS_CART_SUMMARY_UPDATING';
// export const DDS_UP_PAY_BUTTON_DEBOUNCE         = 'DDS_UP_PAY_BUTTON_DEBOUNCE';


export const DDS_UP_NO_ERROR                 = 0;
export const DDS_UP_CARDNUMBER_VALID         = 1;
export const DDS_UP_CARDNUMBER_INVALID       = 2;
export const DDS_UP_EXPIRATION_MONTH_VALID   = 3;
export const DDS_UP_EXPIRATION_MONTH_INVALID = 4;
export const DDS_UP_EXPIRATION_YEAR_VALID    = 5;
export const DDS_UP_EXPIRATION_YEAR_INVALID  = 6;
export const DDS_UP_CVV_VALID                = 7;
export const DDS_UP_CVV_INVALID              = 8;
export const DDS_UP_PROCESSING_ERROR         = 9;

export const DDS_UP_CARDNUMBER       = 1;
export const DDS_UP_EXPIRATION_MONTH = 2;
export const DDS_UP_EXPIRATION_YEAR  = 3;
export const DDS_UP_CVV              = 4;

export const DDS_COUPON_WAIT        = 0;
export const DDS_COUPON_NOT_APPLIED = 1;
export const DDS_COUPON_APPLIED     = 2;

export const DDS_CHECKOUTTAB_NONE           = 0;
export const DDS_CHECKOUTTAB_FIRST          = 1;
export const DDS_CHECKOUTTAB_LOGIN          = 1;
export const DDS_CHECKOUTTAB_SHIPPICKUP     = 2;
export const DDS_CHECKOUTTAB_COUPONSREWARDS = 3;
export const DDS_CHECKOUTTAB_SHIPPING       = 4;
export const DDS_CHECKOUTTAB_BILLING        = 5;
export const DDS_CHECKOUTTAB_ORDERREVIEW    = 6;
export const DDS_CHECKOUTTAB_LAST           = 6;

export const DDS_PAYMENTOPTION_BILL_ME            = 1;
export const DDS_PAYMENTOPTION_BILL_THIRD_PARTY   = 2;
export const DDS_PAYMENTOPTION_PAYROLL_DEDUCT     = 3;
export const DDS_PAYMENTOPTION_CREDIT_CARD        = 4;
export const DDS_PAYMENTOPTION_SPLIT_BILL_PAYROLL = 5;

export const BILLING_OPTION_DESCRIPTION = ['none','bill me','bill third party','payroll deduct','pay now','multi tender'];

export const SHIPPING_SAME_AS_BILLING_FORM_NOT_VISIBLE     = 0;
export const SHIPPING_NOT_SAME_AS_BILLING_FORM_VISIBLE     = 1;
export const SHIPPING_NOT_SAME_AS_BILLING_FORM_NOT_VISIBLE = 2;

export const CUSTOMER_TYPE_COMPANY                     = 0;
export const CUSTOMER_TYPE_INDIVIDUAL                  = 1;
export const FIELD_CHECKOUT_NONE                       = '';
export const FIELD_CHECKOUT_BILLING_AMOUNT             = 'checkout_BillingAmount';
export const FIELD_CHECKOUT_BILLING_OPTION             = 'checkout_BillingOption';
export const FIELD_CHECKOUT_CREDIT_CARD_AMOUNT         = 'checkout_CreditCardAmount';
export const FIELD_CHECKOUT_PAYROLL_DEDUCT_AMOUNT      = 'checkout_PayrollDeductAmount';
export const FIELD_CHECKOUT_STORE_CHARGE_AMOUNT        = 'checkout_StoreChargeAmount';
export const FIELD_CHECKOUT_MULTITENDER_BILLING_OPTION = 'checkout_MultiTenderBillingOption';

// export const CHECKOUT_MULTITENDER_STORE_CHARGE = 0;
// export const CHECKOUT_MULTITENDER_PAYROLL_DEDUCT = 1;

export const COUNTRY_US    = 1;
export const COUNTRY_UK    = 2;
export const COUNTRY_CA    = 3;
export const COUNTRY_AU    = 4;
export const COUNTRY_UST   = 5;
export const COUNTRY_OTHER = 6;

export const BILLING_ADDRESS_FORM_ID       = 'form-billing-address';
export const PAYMENT_PROCESSOR_CREDIT_CARD = 16;
export const PAYMENT_PROCESSOR_PAYPAL      = 9;

export const CART_NO_PAYMENT                       = 0X00000;
export const CART_BILLME                           = 0x00001;
export const CART_BILLTHIRD                        = 0x00010;
export const CART_CC                               = 0x00100;
export const CART_GIFT                             = 0x01000;
export const CART_PAYROLL                          = 0x10000;
export const CART_BILLME_BILLTHIRD                 = CART_BILLME + CART_BILLTHIRD                                      ;
export const CART_BILLME_BILLTHIRD_GIFT            = CART_BILLME + CART_BILLTHIRD + CART_GIFT                          ;
export const CART_BILLME_BILLTHIRD_PAYROLL         = CART_BILLME + CART_BILLTHIRD + CART_PAYROLL                       ;
export const CART_BILLME_BILLTHIRD_PAYROLL_GIFT    = CART_BILLME + CART_BILLTHIRD + CART_PAYROLL + CART_GIFT           ;
export const CART_BILLME_GIFT                      = CART_BILLME + CART_GIFT                                           ;
export const CART_BILLME_PAYROLL                   = CART_BILLME + CART_PAYROLL                                        ;
export const CART_BILLME_PAYROLL_GIFT              = CART_BILLME + CART_PAYROLL + CART_GIFT                            ;
export const CART_BILLTHIRD_GIFT                   = CART_BILLTHIRD + CART_GIFT                                        ;
export const CART_BILLTHIRD_PAYROLL                = CART_BILLTHIRD + CART_PAYROLL                                     ;
export const CART_BILLTHIRD_PAYROLL_GIFT           = CART_BILLTHIRD + CART_PAYROLL + CART_GIFT                         ;
export const CART_CC_BILLME                        = CART_CC + CART_BILLME                                             ;
export const CART_CC_BILLME_BILLTHIRD              = CART_CC + CART_BILLME + CART_BILLTHIRD                            ;
export const CART_CC_BILLME_BILLTHIRD_GIFT         = CART_CC + CART_BILLME + CART_BILLTHIRD + CART_GIFT                ;
export const CART_CC_BILLME_BILLTHIRD_PAYROLL      = CART_CC + CART_BILLME + CART_BILLTHIRD + CART_PAYROLL             ;
export const CART_CC_BILLME_BILLTHIRD_PAYROLL_GIFT = CART_CC + CART_BILLME + CART_BILLTHIRD + CART_PAYROLL + CART_GIFT ;
export const CART_CC_BILLME_GIFT                   = CART_CC + CART_BILLME + CART_GIFT                                 ;
export const CART_CC_BILLME_PAYROLL                = CART_CC + CART_BILLME + CART_PAYROLL                              ;
export const CART_CC_BILLME_PAYROLL_GIFT           = CART_CC + CART_BILLME + CART_PAYROLL + CART_GIFT                  ;
export const CART_CC_BILLTHIRD                     = CART_CC + CART_BILLTHIRD                                          ;
export const CART_CC_BILLTHIRD_GIFT                = CART_CC + CART_BILLTHIRD + CART_GIFT                              ;
export const CART_CC_BILLTHIRD_PAYROLL             = CART_CC + CART_BILLTHIRD + CART_PAYROLL                           ;
export const CART_CC_BILLTHIRD_PAYROLL_GIFT        = CART_CC + CART_BILLTHIRD + CART_PAYROLL + CART_GIFT               ;
export const CART_CC_GIFT                          = CART_CC + CART_GIFT                                               ;
export const CART_CC_PAYROLL                       = CART_CC + CART_PAYROLL                                            ;
export const CART_CC_PAYROLL_GIFT                  = CART_CC + CART_PAYROLL + CART_GIFT                                ;
export const CART_PAYROLL_GIFT                     = CART_PAYROLL + CART_GIFT                                          ;

export const ADDRESS_ID_BILLING                    = 0;
export const ADDRESS_ID_SHIPPING                   = 1;
export const ADDRESS_ID_THIRDPARTY                 = 2;
