import { createTheme } from '@mui/material/styles';
import { red, grey, green } from '@mui/material/colors';

let UswTheme = createTheme({
    typography: {
        fontSize: 13,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
    }
});

UswTheme = createTheme(UswTheme, {
    palette: {
        primary: {
            main: "#1779ba",
            // main: green[500],
        },
        // augmentColor is a step that Material-UI automatically does for the standard palette colors.
        monochrome: UswTheme.palette.augmentColor({
            color: { main: grey[700] },
            name: 'monochrome',
        }),
    }, usw: {
        spacingNormal: '.5rem',
    }, uniformPay: {
        hasFocus: {
            border: '1px solid #8a8a8a',
            backgroundColor: '#fefefe',
            outline: 'none',
            boxShadow: '0 0 5px #cacaca',
            transition: 'box-shadow 0.5s, border-color 0.25s ease-in-out'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: "#2790CE"
                }
            }
        }
    },
    typography: {
        // fontSize: "5rem",
        button: {
            textTransform: "none",
            fontSize: ".9rem"
        }
    }
});

export default UswTheme;