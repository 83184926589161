import { Typography } from '@mui/material';
import {
    createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useRouteError
} from 'react-router-dom';
import './App.css';
import { store } from './app/store';
import ComponentError from './common/ComponentError';
import Message from './common/Message';
import './css/app.css';
import Home from './features/home/Home';
import { modalPopupActions } from './features/modalPopup/modalPopupSlice';
import UniformPayExternal from './features/uniformPay/UniformPayExternal';
import { uniformPayActions } from './features/uniformPay/uniformPaySlice';
import RootLayout from './layouts/RootLayout';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={<RootLayout />}
            errorElement={<ErrorBoundary />}
        >
            <Route
                path=""
                element={<Home />}
            />
            <Route
                path="pay/:query"
                element={<UniformPayExternal />}
            />
        </Route>
    )

);

function App() {
    console.log('App initializing');
    //@ts-expect-error
    window.Store = store;
    //@ts-expect-error
    window.DDS_uniformPayActions = uniformPayActions;
    //@ts-expect-error
    window.DDS_ComponentError = ComponentError;
    //@ts-expect-error
    window.DDS_Message = Message
    //@ts-expect-error
    window.DDS_modalPopupActions = modalPopupActions

    return (
        <RouterProvider router={router} />
    );
}

function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    return (
        <>
            <Typography variant="h4">The Uniform Solution</Typography>
            <Typography variant="h5">UniformPay</Typography>
        </>
    )
}

export default App;
