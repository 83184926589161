import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import * as constants from '../../common/constants';
import { BrowseSettings } from '../account/AccountTypes';
import { OrderCompletionRequest, OrderCompletionResponse, UniformPayState } from './uniformPayTypes';

// const middleware = [thunk]

// const orderCompletionResponse: OrderCompletionResponse = {
//     orderCompletionResponse: {
//         isComplete: false,
//         isEmailSent: false,
//         isNewAccount: false,
//         emailAddress: '',
//         errorMsg: '',
//         paymentAmount: 0,
//         paymentProcessor: ''
//     }
// }

const initialState: UniformPayState = {
    blacklisted: false,
    cardErrorCount: 0,
    cardNumberHasFocus: false,
    cardNumberValid: true,
    cvvHasFocus: false,
    cvvValid: true,
    errors: [],
    expiryMonthHasFocus: false,
    expiryMonthValid: true,
    expiryYearHasFocus: false,
    expiryYearValid: true,
    formLoaded: false,
    isCountryVisible: false,
    testOrderCompletionFailure: false,
    lockedOut: false,
    orderCompletionResponse: {
        isComplete: false,
        isEmailSent: false,
        isNewAccount: false,
        emailAddress: '',
        errorMsg: '',
        paymentAmount: 0,
        paymentProcessor: ''
    },
    payButtonDisabled: true,
    paymentErrorLoggingFailed: false,
    recaptchaEnabled: false,
    submitError: '',
    submitErrorCount: 0,
    submittingForm: false,
    waiting: false,
};

export const logCardAuthorizationFailure = createAsyncThunk<UniformPayState, string>(
    'uniformPay/LogCardAuthorizationFailure',
    async (error: string) => {
        const response = await axios.get('/LogCardAuthorizationFailure', {
            params: { paymentType: 'uniformpay', error: error },
        });
        return response.data;
    }
);

export const clearCardAuthorizationFailure = createAsyncThunk(
    'uniformPay/ClearCardAuthorizationFailure',
    async () => {
        const response = await axios.get('/ClearCardAuthorizationFailure', {
            params: { paymentType: 'uniformpay' },
        });
        return null;
    }
);

export const requestOrderCompletion = createAsyncThunk<OrderCompletionResponse>(
    'uniformPay/requestOrderCompletion', async (_, thunkApi) => {
        console.log('called requestOrderCompletion');
        try {
            let params: OrderCompletionRequest = {
                step: 2,
                tp: 'up',
            }
            let url = '/CheckoutProcessOrderContent';
            console.log('url', url);
            let response = await axios.post<OrderCompletionResponse>(url, params);
            return response?.data as OrderCompletionResponse;

            // let params = {
            //     step: 2,
            //     tp: 'up',
            //     transactionId: request.transactionId,
            //     hostedPaymentsResponse: request.hostedPaymentsResponse
            // }
            // let url = '/CheckoutProcessOrder';
            // const options = {
            //     method: 'POST',
            //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
            //     data: qs.stringify(params),
            //     url,
            // };
            // let response = await axios(options);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response?.data)
        }
    })



const uniformPaySlice = createSlice({
    name: 'uniformPay',
    initialState,
    reducers: {
        setCardErrorCount: (state, action) => {
            state.cardErrorCount = action.payload;
        },
        // setCountryVisibility: (state, action) => {
        //     state.isCountryVisible = !isNumericPostalCode(action.payload);
        //     console.log('state.isCountryVisible', state.isCountryVisible);
        // },
        setFieldStatus: (state, action) => {
            switch (action.payload.fieldId) {
                case constants.DDS_UP_CARDNUMBER:
                    state.cardNumberValid = action.payload.isValid;
                    break;
                case constants.DDS_UP_EXPIRATION_MONTH:
                    state.expiryMonthValid = action.payload.isValid;
                    break;
                case constants.DDS_UP_EXPIRATION_YEAR:
                    state.expiryYearValid = action.payload.isValid;
                    break;
                case constants.DDS_UP_CVV:
                    state.cvvValid = action.payload.isValid;
                    break;
            }
        },
        setSubmitError: (state, action) => {
            state.submitError = action.payload.msg;
            state.submitErrorCount += 1;
        },
        setFormLoaded: (state, action) => {
            state.formLoaded = action.payload;
        },
        setTestOrderCompletionFailure: (state, action: PayloadAction<boolean>) => {
            state.testOrderCompletionFailure = action.payload;
        },
        setRecaptchaEnabled: (state, action) => {
            state.recaptchaEnabled = action.payload;
        },
        setInputFocus: (state, action) => {
            switch (action.payload.fieldId) {
                case constants.DDS_UP_CARDNUMBER:
                    state.cardNumberHasFocus = action.payload.hasFocus;
                    break;
                case constants.DDS_UP_EXPIRATION_MONTH:
                    state.expiryMonthHasFocus = action.payload.hasFocus;
                    break;
                case constants.DDS_UP_EXPIRATION_YEAR:
                    state.expiryYearHasFocus = action.payload.hasFocus;
                    break;
                case constants.DDS_UP_CVV:
                    state.cvvHasFocus = action.payload.hasFocus;
                    break;
            }
        },
        // debouncePayButton: (state, action) => {
        // 	state.payButtonDebounce = action.payload;
        // },
        disablePayButton: (state) => {
            state.payButtonDisabled = true;
        },
        enablePayButton: (state) => {
            state.payButtonDisabled = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logCardAuthorizationFailure.fulfilled, (state, action) => {
            state.paymentErrorLoggingFailed = false;
            state.lockedOut = action.payload.lockedOut;
            state.blacklisted = action.payload.blacklisted;
        })
        builder.addCase(logCardAuthorizationFailure.rejected, (state) => {
            state.paymentErrorLoggingFailed = true;
        })
        builder.addCase(clearCardAuthorizationFailure.fulfilled, (state) => {
            state.lockedOut = false;
            state.blacklisted = false;
        })
        builder.addCase(requestOrderCompletion.pending, (state, action) => {
            console.log('requestOrderCompletion.pending');
            state.orderCompletionResponse = state.orderCompletionResponse || {};
            state.orderCompletionResponse.isComplete = false;
        })
        builder.addCase(requestOrderCompletion.fulfilled, (state, action) => {
            console.log('requestOrderCompletion.fulfilled', action.payload);
            try {
                state.orderCompletionResponse = action.payload;
                state.orderCompletionResponse.isComplete = true;
            } catch (e) {
                state.orderCompletionResponse = state.orderCompletionResponse || {};
                state.orderCompletionResponse.errorMsg = 'An unexpected error has occurred: unable to process the response from the server';
                state.orderCompletionResponse.isComplete = true;
            }
        })
        builder.addCase(requestOrderCompletion.rejected, (state, action) => {
            console.log('requestOrderCompletion.rejected');
            if (state.testOrderCompletionFailure) {
                // return some dummy data for testing
                state.orderCompletionResponse = state.orderCompletionResponse || {};
                state.orderCompletionResponse.isComplete = true;
                state.orderCompletionResponse.paymentAmount = 12.33;
            }
        })
    },
});

// const isNumericPostalCode = (zipPostalCode) => {
//     // from https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
//     if (typeof zipPostalCode != "string") return false // we only process strings!  
//     return !isNaN(zipPostalCode) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
//         !isNaN(parseFloat(zipPostalCode)) // ...and ensure strings of whitespace fail
// }


export const uniformPayActions = uniformPaySlice.actions;
export default uniformPaySlice.reducer;