import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalPopupState = {
    message: string;
    open: boolean;
    pleaseWait: boolean;
    title: string;
    resetCaptchaOnClose: boolean;
}

export type ModalPopupProps  = {
    resetCaptchaOnClose?: boolean
}

const initialState = {
    message: '',
    open: false,
    pleaseWait: false,
    resetCaptchaOnClose: false,
    title: '',
}

const modalPopupSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        close: (state) => {
			state.title = '';
			state.message = '';
			state.pleaseWait = false;
			state.open = false;
        },
        resetCaptchaOnClose: (state, action: PayloadAction<boolean>) => {
            state.resetCaptchaOnClose = action.payload;
        },
        showMessage: (state,action) => {
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.pleaseWait = false
			state.open = true;
        },
        showPleaseWait: (state,action) => {
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.pleaseWait = action.payload.pleaseWait;
			state.open = true;
        },
    }
});

export const modalPopupActions = modalPopupSlice.actions;

export default modalPopupSlice.reducer;