import { RootState } from "../app/store";
import { CurrencyEquate } from "../common/Types";
import { CheckoutBillingOption } from "../features/cart/cartTypes";
import { UserState } from "../features/user/userStateTypes";

// Cribbed from https://www.npmjs.com/package/extract-numbers
export function extractNumber(str: string, index: number = 0, places: number = 2): number {
    if (typeof str !== 'string' || str === '') return 0;
    var regex = /[+-]?\d+(\.\d+)?/g;
    var numbers: any = str.match(regex);
    var number: string = numbers[index];
    return Math.round(parseFloat(number) * 100) / 100;
}

export const formatAsCurrency = (value: number): string => {
    return currencyFormatter.format(value);
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const getCurrencySymbol = (equate: number): string => {
    if (typeof equate === 'undefined') return '$';
    let symbol = '$';   // Default for Dollar and Peso
    switch (equate) {
        case CurrencyEquate.Euro:
            symbol = '€';
            break;
        case CurrencyEquate.Pound:
            symbol = '£';
            break;
    }
    return symbol;
}

export const getMixedModeUrl = (state: RootState, url: string, request?: any): string => {
    if (process.env.NODE_ENV !== 'production' && typeof state.user.mixedModeServerPort !== 'undefined' && state.user.mixedModeServerPort !== 0) {
        url = 'http://localhost:' + state.user.mixedModeServerPort.toString() + url;
        if (typeof request !== 'undefined') {
            request.isMixedModeTestingEnabled = true;
        } else {
            url += '?isMixedModeTestingEnabled=1'
        }
    }
    return url;
}

export const getBillingOptionDescription = (billingOption: CheckoutBillingOption): string => {
    switch (billingOption) {
        case CheckoutBillingOption.BillMe:
            return 'Bill Me';
        case CheckoutBillingOption.BillThirdParty:
            return 'Bill Third Party';
        case CheckoutBillingOption.MultiTender:
            return 'Multi Tender';
        case CheckoutBillingOption.None:
            return 'None';
        case CheckoutBillingOption.PayNow:
            return 'Pay Now';
        case CheckoutBillingOption.PayrollDeduct:
            return 'Payroll';
    }
}
