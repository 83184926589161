import { Typography } from '@mui/material';
import * as React from 'react';

const Home = () => {
    return (
        <>
            <Typography variant="h4">The Uniform Solution</Typography>
            <Typography variant="h5">UniformPay</Typography>
        </>
    )
}

export default Home;