import { configureStore } from '@reduxjs/toolkit'
import modalPopupReducer from '../features/modalPopup/modalPopupSlice'
import uniformPayReducer from '../features/uniformPay/uniformPaySlice'
import userReducer from '../features/user/userSlice'

const reducer = {
    // admin reducers
    modalPopup: modalPopupReducer,
    uniformPay: uniformPayReducer,
    user: userReducer,
}

const preloadedState = {};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),//.concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
  })

export function getStoreWithState(preloadedState?: RootState) {
  return configureStore({ reducer, preloadedState });
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
