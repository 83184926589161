import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { connect } from 'formik';
import * as React from 'react';
import { useAppSelector } from '../../app/hooks';
import { useAppDispatch } from '../../app/hooks';
import { RootState, store } from '../../app/store';
import { logCardAuthorizationFailure, uniformPayActions } from '../uniformPay/uniformPaySlice';
import { modalPopupActions, ModalPopupProps, ModalPopupState } from './modalPopupSlice';


function getModalStyle() {
    const top = 35;
    const left = 50;
    return {
        top: `${top}%`,
        margin: 'auto'
        //left: `${left}%`,
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const ModalPopup = (props: ModalPopupProps) => {

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (typeof props.resetCaptchaOnClose !== 'undefined' ) {
            dispatch(modalPopupActions.resetCaptchaOnClose(props.resetCaptchaOnClose));
        }
    },[]);

    const state = useAppSelector((state) => state.modalPopup);


    // getModalStyle is only created on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const handleClose = () => {
        if (!state.pleaseWait) {
            store.dispatch(modalPopupActions.close()); 
        }
        if (state.resetCaptchaOnClose) {
            // @ts-ignore            
            grecaptcha.reset();
            store.dispatch(logCardAuthorizationFailure(state.message));
            store.dispatch(uniformPayActions.disablePayButton());
        }
    };

    return (
        <div>
            <Modal
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                open={state.open}
                onClose={handleClose}            >
                <Box
                    style={modalStyle}
                    sx={(theme) => ({
                        position: 'absolute',
                        width: 400,
                        backgroundColor: theme.palette.background.paper,
                        border: '1px solid #000',
                        boxShadow: theme.shadows[5],
                        padding: theme.spacing(2, 4, 3),
                        borderRadius: 4
                    })}
                >
                    <Box display="flex" justifyContent="center"><h2 id="simple-modal-title">{state.title}</h2></Box>
                    {!!state.message ?
                        <Box display="flex" justifyContent="center"><p id="alert-text">{state.message}</p></Box>
                        : null}
                    {state.pleaseWait ?
                        <Box display="flex" justifyContent="center"><CircularProgress /></Box>
                        :
                        <Box display="flex" justifyContent="center"><button className="button hollow" onClick={handleClose} >Close</button></Box>
                    }
                </Box>
            </Modal>
        </div>
    );
}


export default ModalPopup
