import { BrowseAction, BrowseColumnSort, BrowseFilter, BrowseSettings } from "./AccountTypes";

export class BrowseRequest {
    static clearFilter(prevSettings: BrowseSettings): BrowseSettings {
        let settings = { ...prevSettings } as BrowseSettings;
        // console.log('setBrowseLocator action', action.payload);
        // let settings = getBrowseSettings(state, action.payload);
        settings.filterValue = ''
        return settings;
    }

    static columnSort(prevSettings: BrowseSettings, sortSettings: BrowseColumnSort): BrowseSettings {
        console.log('setBrowseColumnSort');
        let settings = { ...prevSettings } as BrowseSettings;
        // clunky code for backward compatibility with the browse service
        settings.browseAction = BrowseAction.Sort;
        settings.balanceColumnSorted = false;
        settings.dateColumnSorted = false;
        settings.invoiceColumnSorted = false;
        settings.soldToColumnSorted = false;
        settings.totalColumnSorted = false;
        settings.sortColumn = ''
        switch (sortSettings.columnName) {
            case 'date':
                settings.dateColumnSorted = true;
                break;
            case 'invoice':
                settings.invoiceColumnSorted = true;
                break;
            case 'soldTo':
                settings.soldToColumnSorted = true;
                break;
            case 'total':
                settings.totalColumnSorted = true;
                break;
            case 'balance':
                settings.balanceColumnSorted = true;
                break;
        }
        settings.sortColumn = sortSettings.columnName;
        if (settings?.sortColumnPrevious !== settings.sortColumn) {
            settings.columnSortDirection = 'n';
        } else {
            if (settings?.columnSortDirection === 's') {
                settings.columnSortDirection = 'n';
            } else {
                settings.columnSortDirection = 's';
            }
        }
        // console.log('settings.refreshCounter', settings.refreshCounter);
        // settings.sortColumnPrevious = settings.sortColumn;
        // settings.refreshCounter = settings.refreshCounter || 0;
        // settings.refreshCounter++;

        // console.log('settings.refreshCounter', settings.refreshCounter);
        // switch (action.payload.id) {
        //     case BrowseId.Sales:
        //         state.salesBrowseSettings = settings;
        //         break;
        // }
        return settings;
    }

    static filter(prevSettings: BrowseSettings, filterSettings: BrowseFilter): BrowseSettings {
        let settings = { ...prevSettings } as BrowseSettings;
        settings.browseAction = BrowseAction.Filter;
        settings.filterColumn = filterSettings.columnName
        settings.filterValue = filterSettings.value;
        settings.locatorPositionTo = filterSettings.positionTo;
        return settings;
    }
    static goToPage(prevSettings: BrowseSettings, goTo: string): BrowseSettings {
        let settings = { ...prevSettings } as BrowseSettings;
        settings.browseAction = BrowseAction.GoTo;
        settings.goTo = goTo;
        return settings;
    }
    static itemsPerPage(prevSettings: BrowseSettings, perPage: number): BrowseSettings {
        let settings = { ...prevSettings } as BrowseSettings;
        settings.browseAction = BrowseAction.ItemsPerPage;
        settings.itemsPerPage = perPage;
        return settings;
    }
    static cleanUpRequest(settings: BrowseSettings, isMixedModeTestingEnabled: boolean): BrowseSettings {
        console.log('cleanUpBrowseRequest',settings);
        let request = { ...settings } as BrowseSettings;
        // if (settings.browseId) {
        //     request.browseId = settings.browseId;
        // }
        // if (settings.browseType) {
        //     request.browseType = settings.browseType;
        // }
        // // settings.browseAction = settings.browseAction || BrowseAction.None;
        // console.log('settings.browseAction',settings.browseAction);
        // switch (settings.browseAction) {
        //     case BrowseAction.Sort:
        //         if (settings.balanceColumnSorted) request.balanceColumnSorted = settings.balanceColumnSorted;
        //         if (settings.columnSortDirection) request.columnSortDirection = settings.columnSortDirection;
        //         if (settings.dateColumnSorted) request.dateColumnSorted = settings.dateColumnSorted;
        //         if (settings.invoiceColumnSorted) request.invoiceColumnSorted = settings.invoiceColumnSorted;
        //         if (settings.soldToColumnSorted) request.soldToColumnSorted = settings.soldToColumnSorted;
        //         if (settings.sortColumn) request.sortColumn = settings.sortColumn;
        //         if (settings.totalColumnSorted) request.totalColumnSorted = settings.totalColumnSorted;
        //         break;
        //     case BrowseAction.Filter:
        //         if (settings.endDate) request.endDate = settings.endDate;
        //         if (settings.startDate) request.startDate = settings.startDate;
        //         if (settings.soldTo) request.soldTo = settings.soldTo;
        //         request.locator = settings.filterColumn;
        //         request.value = settings.filterValue;
        //         break;
        //     case BrowseAction.ItemsPerPage:
        //         if (settings.itemsPerPage) request.itemsPerPage = settings.itemsPerPage;
        //         if (settings.perPage100) request.perPage100 = settings.perPage100;
        //         if (settings.perPage10) request.perPage10 = settings.perPage10;
        //         if (settings.perPage25) request.perPage25 = settings.perPage25;
        //         if (settings.perPage50) request.perPage50 = settings.perPage50;
        //         break;
        //     case BrowseAction.GoTo:
        //         if (settings.browseAtBottom) request.browseAtBottom = settings.browseAtBottom;
        //         if (settings.browseAtTop) request.browseAtTop = settings.browseAtTop;
        //         if (settings.currentPage) request.currentPage = settings.currentPage;
        //         if (settings.goTo) request.goTo = settings.goTo;
        //         break;
        // }
        // if (settings.isFormSubmission) request.isFormSubmission = settings.isFormSubmission;
        // if (settings.showSubAccount) request.showSubAccount = settings.showSubAccount;
        request.isMixedModeTestingEnabled = isMixedModeTestingEnabled;
        console.log('cleaned request', request);
        return request;
    }

}





    // triggerBrowseRefresh(settings)

// setBrowseFilter: (state, action: PayloadAction<BrowseFilter>) => {
//     // triggerBrowseRefresh(settings);
// },
// setBrowseGoToPage: (settings, action: PayloadAction<BrowseGoToPage>) => {
//     let settings = getBrowseSettings(state, action.payload.id);
//     // triggerBrowseRefresh(settings);
// },

//         // setBrowseIsInitialLoadComplete: (state, action: PayloadAction<BrowseId>) => {
//         //     let settings = getBrowseSettings(state, action.payload);
//         //     settings.isBrowseReadyForUse = true;
//         // },
//         setBrowseItemsPerPage: (state, action: PayloadAction<BrowseItemsPerPage>) => {
//             let settings = getBrowseSettings(state, action.payload.id);
//             settings.browseAction = BrowseAction.ItemsPerPage;
//             settings.itemsPerPage = action.payload.itemsPerPage;
//             // triggerBrowseRefresh(settings);
//         },
//         // setBrowseSettings: (state, action: PayloadAction<BrowseSettings>) => {
//         //     switch (action.payload.id) {
//         //         case BrowseId.Sales:
//         //             state.salesBrowseSettings = action.payload;
//         //             break;
//         //     }
//         // },