import { Outlet, NavLink } from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import UswTheme from '../common/ThemeFactory';
import { Provider } from 'react-redux';
import { store } from '../app/store';
import {Helmet} from "react-helmet";


export default function RootLayout() {
    return (
        <ThemeProvider theme={UswTheme}>
            <Provider store={store}>
                <div className="root-layout">
                    <main>
                        <Outlet />
                    </main>
                </div>
            </Provider>
        </ThemeProvider>
    )
}
