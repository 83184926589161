import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { getCurrencySymbol, getMixedModeUrl } from '../../utils/stringUtils';
import { AccountSalesBrowseResponse, BrowseAction, BrowseId, BrowseSettings } from '../account/AccountTypes';
import { BrowseRequest } from '../account/BrowseUtils';
import { LoadState, StoreInfo, StoreSelectionMode, UserState } from './userStateTypes';

export type SelectStore = {
    storeNumber: number;
}

const initialState: UserState = {
    error: '',
    isMixedModeTestingEnabled: false,
    loadState: LoadState.Unknown,
    mixedModeServerPort: 80,
    salesBrowseSettings: {
        refreshCounter: 0,
        loading: true,
        perPage25: true,
        sortColumn: 'date',
    },
    selectedStoreNumber: 0,
    storeSelectionMode: StoreSelectionMode.Enabled,
    selectedStoreName: '',
    showStoreList: false,
    currencyEquate: 1,
    currencySymbol: '$',
};

export const getAccountSales = createAsyncThunk<AccountSalesBrowseResponse, BrowseSettings, { state: RootState }>(
    'uniformPay/getAccountSales', async (request: BrowseSettings, thunkApi,) => {
        // console.log('getAccountSales',request);
        try {
            const state = thunkApi.getState();
            var url = getMixedModeUrl(state,'/AccountSalesBrowseService',request);
            // console.log('url', url);
            let response = await axios.post<AccountSalesBrowseResponse>(url, BrowseRequest.cleanUpRequest(request, state.user.isMixedModeTestingEnabled || false));
            return response?.data as AccountSalesBrowseResponse;
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response?.data)
        }
    })

export const getUserStateFromServer = createAsyncThunk<UserState, null, { state: RootState }>(
    'storeSelection/getUserState', async (_, thunkApi) => {
        // console.log('SERVER CALL storeSelection/getUserState');
        try {
            var url = getMixedModeUrl(thunkApi.getState(),'/GetUserState');
            let response = await axios.get<UserState>(url)
            return response?.data
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response?.data)
        }
    })

export const postSelectedStoreToServer = createAsyncThunk<UserState, SelectStore, { state: RootState }>(
    'storeSelection/setUserState', async (selectStore: SelectStore, thunkApi) => {
        // console.log('called setUserState', selectStore);
        try {
            let userState: UserState = {
                selectedStoreNumber: selectStore.storeNumber,
                salesBrowseSettings: {}
            }
            const state = thunkApi.getState();
            var url = getMixedModeUrl(state,'/SetUserState',userState);
            const response = await axios.post(url, userState)
            return response?.data
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response?.data)
        }
    })

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        hideStoreList: (state) => {
            state.showStoreList = false;
        },
        setIsMixedModeTestingEnabled: (state, action: PayloadAction<boolean>) => {
            state.isMixedModeTestingEnabled = action.payload;
        },
        setSelectedStoreNumber: (state, action: PayloadAction<number>) => {
            state.selectedStoreNumber = action.payload;
            setSelectedStoreName(state);
        },
        setStoreList: (state, action: PayloadAction<Array<StoreInfo>>) => {
            state.stores = action.payload;
        },
        setStoreSelectionRequired: (state, action: PayloadAction<boolean>) => {
            state.isStoreSelectionRequired = action.payload;
            if (state.isStoreSelectionRequired) {
                state.showStoreList = true;
            }
        },
        showStoreList: (state) => {
            state.showStoreList = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserStateFromServer.pending, (state) => {
            // console.log('getUserStateFromServer.pending')
            state.loadState = LoadState.Loading;
        })
        builder.addCase(getUserStateFromServer.fulfilled, (state, { payload } ) => {
            // console.log('getUserStateFromServer.fulfilled', payload);
            state.loadState = LoadState.Loaded;
            state.error = '';
            state.storeSelectionMode = payload.storeSelectionMode;
            state.selectedStoreNumber = payload.selectedStoreNumber;
            state.stores = payload.stores;
            state.activeContractName = payload.activeContractName;
            state.currencyEquate = payload.currencyEquate || state.currencyEquate;
            state.currencySymbol = getCurrencySymbol(state.currencyEquate || 1);
            setSelectedStoreName(state);
            state.isStoreSelectionRequired = false;
            state.isMixedModeTestingEnabled = payload.isMixedModeTestingEnabled;
            let href = document.location.href.toLowerCase();
            if (href.includes('/itemdetails')) {
                // console.log('on itemdetails page')
                if (state.selectedStoreNumber === 0) {
                    // console.log('state.isStoreSelectionRequired = true');
                    state.isStoreSelectionRequired = true;
                    state.showStoreList = true;
                }
            }

        })
        builder.addCase(getUserStateFromServer.rejected, (state, action) => {
            // console.log('getUserStateFromServer.rejected');
            // console.log('payload', action.payload);

        });
        builder.addCase(postSelectedStoreToServer.pending, (state) => {
            // console.log('postSelectedStoreToServer.pending')
        })
        builder.addCase(postSelectedStoreToServer.fulfilled, (state, action) => {
            // console.log('postSelectedStoreToServer.fulfilled', action.payload);
            state.selectedStoreNumber = action.payload.selectedStoreNumber;
            setSelectedStoreName(state);
            state.showStoreList = false;
            state.error = '';
        })
        builder.addCase(postSelectedStoreToServer.rejected, (state, action) => {
            // console.log('postSelectedStoreToServer.rejected');
            // console.log('payload', action.payload);
        });
        builder.addCase(getAccountSales.pending, (state, action) => {
            // console.log('getAccountSales.pending');
            state.salesBrowseSettings.loading = true;
        })
        builder.addCase(getAccountSales.fulfilled, (state, action) => {
            // console.log('getAccountSales.fulfilled', action.payload);
            // console.log('state.salesBrowseSettings', state.salesBrowseSettings);
            // console.log('settings', settings);
            // console.log('state.salesBrowseSettings.refreshCounter', state.salesBrowseSettings.refreshCounter);
            // state.salesBrowseSettings = action.payload;
            state.salesBrowseData = action.payload;
            state.salesBrowseSettings.browseAtBottom = action.payload.browseAtBottom;
            state.salesBrowseSettings.browseAtTop = action.payload.browseAtTop;
            state.salesBrowseSettings.goTo = '';
            state.salesBrowseSettings.loading = false;
            state.salesBrowseSettings.browseAction = BrowseAction.None;
            state.salesBrowseSettings.isBrowseReadyForUse = true;
            state.salesBrowseSettings.sortDirection = action.payload.sortDirection;
            state.salesBrowseSettings.soldTo = action.payload.soldTo;
        })
        builder.addCase(getAccountSales.rejected, (state, action) => {
            // console.log('getAccountSales.rejected');
            state.salesBrowseSettings.loading = false;
        })
    }
});

const setSelectedStoreName = (state: UserState) => {
    state.selectedStoreName = '';
    if (state.stores && state.stores.length > 0) {
        state.stores.forEach((store: StoreInfo) => {
            if (store.number === state.selectedStoreNumber) {
                state.selectedStoreName = store.name;
            }
        })
    }
}

const getBrowseSettings = (state: UserState, browseId: BrowseId): BrowseSettings => {
    switch (browseId) {
        case BrowseId.Sales:
            return state.salesBrowseSettings;
    }
    return {};
}



export const userActions = userSlice.actions;
export default userSlice.reducer;