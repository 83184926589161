import { useParams } from "react-router";
import UniformPayHostedControls from "./UniformPayHostedControls";
import { UniformPayProps } from './uniformPayTypes';
import useScript from 'react-script-hook';
// import '../../js/app.js';
import $ from 'jquery';
import { Box } from "@mui/material";


const UniformPayExternal = () => {

    const { query } = useParams();

    const jsonIn: UniformPayProps = JSON.parse(window.atob(query || ''));
    console.log(jsonIn);

    //@ts-expect-error
    window.handleGatewayError = function (gatewayResponse) {
        //console.log('window.handleGatewayError');
        //console.log(gatewayResponse);
        //@ts-expect-error
        DDS_showUniformPayErrorStatus(gatewayResponse);
    }


    let upScript = 'https://hostedpayments.fullsteampay.net/js/hostedcontrols/1.0.0/fullsteam.hostedcontrols.js';
    if (jsonIn.testing) {
        upScript = 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/1.0.0/fullsteam.hostedcontrols.js';
    }
    console.log(upScript);

    const [loading, error] = useScript({ src: upScript });



    if (loading) return <h3>Loading...</h3>;
    if (error) return <h3>Failed to load payment control: {error.message}</h3>;

    console.log('UniformPayExternal returning UniformPayHostedControls');
    return (
        <Box sx={{paddingLeft: '.5rem', paddingRight: '.5rem'}}>
            <UniformPayHostedControls
                {...jsonIn}
            />
        </Box>

    );
}
export default UniformPayExternal;
