import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';


export default function ComponentError(props) {

    return (
        <Box 
            sx={(theme) => ({
                width: '100%',
                '& > * + *': {
                    marginTop: theme.spacing(2),
                },
            })}
        >
            <Alert severity="error">{props.msg}</Alert>
        </Box>
    );
}

ComponentError.defaultProps = {
    msg: 'An unknown error has occurred'
}
